<template>
      <div class="xrayImg">
        <div class="imgW" v-ripple>
           <img v-lazy="xrayMap?.rsltUrl + xrayMap?.modThumbNm" @click="showDetail(xrayMap?.rsltUrl + xrayMap?.modNm)" />
        </div>
        <div class="txtW">
          <p class="date">촬영일: {{ xrayMap?.pictureDtStr || '' }}</p>
          <p class="txt">{{xrayMap?.handsType}}측 수부 {{READING_HANDS_NAME}} : {{xrayMap?.boneAgeStr}} ({{xrayMap?.boneAge}})</p>
        </div>
      </div>
</template>
<script>
export default {
    components: {
    },
    props: {
        xrayMap:{
            default() {
                return {
                  rsltUrl: '',
                  modNm: '',
                  modThumbNm: '',
                  pictureDt: '',
                  handsType: '',
                  atTimeAge: 0,
                  boneAge: 0,
                };
            },
        },
    },
    data() {
        return {
          
        };
    },
    computed: {},
    watch: {
    },
    created() {},
    mounted() {
    },
    destroyed() {},
    methods: {
    },
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";

.xrayImg{
  height:calc(100vh - 494px);
  .txtW{
    height: auto;
    min-height: 105px;
  }
}
</style>